const state = {
  topActive: '', // top导航
  headerActive: '', // header导航
  catActive: ''// cat导航
}
const mutations = {
  setHeaderActive (state, payload) {
    state.headerActive = payload
  },
  setCatActive (state, payload) {
    state.catActive = payload
  }
}
const store = {
  namespaced: true,
  state,
  mutations
}
export default store
