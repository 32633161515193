const state = {
  adminInfo: '' // 管理员登陆信息
}
const getters = {
//   getUsers(state){ //获取访问该页面的所有用户
//     // 对数据清理-除去脏数据
//   	if (state.users.includes('*')) delete state.users['*']
//     	return state.users;
//   }
}
const mutations = {
  setAdminInfo (state, payload) {
    state.adminInfo = payload
  }
}
// 注意和仓库的区别
const store = {
  // namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
  // 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
  namespaced: true,
  state,
  getters,
  mutations
}
export default store
