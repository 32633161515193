const state = {
  userInfo: '' // 用户登陆信息
}
const mutations = {
  setUserInfo (state, payload) {
    state.userInfo = payload
  }
}
const store = {
  namespaced: true,
  state,
  mutations
}
export default store
// export default {
//   namespaced: true,
//   state: () => {
//     return {
//       userInfo: {
//         token: '123',
//         username: '张三'
//       }
//     }
//   },
//   mutations: { // 同步执行
//     updateName (state, payload) {
//       state.userInfo.username = payload
//     }
//   },
//   actions: { // 异步执行（间接执行）
//     updateNameAsync ({ commit }) {
//       setTimeout(() => {
//         commit('updateName', payload)
//       }, 2000)
//     }
//   }
// }
