const state = {
  webData: '', // 网站基础数据
  title: ''// 标题
}
const getters = {
  getCatname: (state) => (id) => { // 根据ID获取分类名称
    const cat = state.webData.cates.find(item => item.id === id)
    if (cat) {
      return cat.catname
    }
  },
  getProCat: (state) => (id) => { // 根据ID获取产品分类名称20240427
    const cat = state.webData.proCat.find(item => item.id === id)
    if (cat) {
      return cat.catname
    }
  },
  getInfos: (state) => { // 获取所有已审核信息列表
    return state.webData.infos.filter(item => item.flag === 1)
  },
  getInfo: (state, getters) => (id) => { // 根据ID获取1条信息
    const info = getters.getInfos.find(item => item.id === id)
    if (info) {
      return info
    }
  },
  getPro: (state, getters) => (id) => { // 根据ID获取1款产品20240427
    const pro = state.webData.pros.find(item => item.id === id)
    if (pro) {
      return pro
    }
  },
  getCatInfos: (state, getters) => (catid) => { // 根据ID获取类目信息列表
    return getters.getInfos.filter(item => item.cateid === catid)
  },
  getCatPros: (state, getters) => (catid) => { // 根据ID获取类目产品列表20240427
    return state.webData.pros.filter(item => item.catid === catid)
  },
  getShopPros: (state, getters) => (sellerid) => { // 根据卖家ID获取产品列表20240430
    return state.webData.pros.filter(item => item.sellerid === sellerid)
  },
  getTopInfos: (state, getters) => { // 获取置顶信息列表
    return getters.getInfos.filter(item => item.top === 1)
  },
  getTopPros: (state, getters) => { // 获取置顶产品列表20240427
    return state.webData.pros.filter(item => item.top === 1)
  },
  getMember: (state) => (id) => { // 根据ID获取1条用户信息
    return state.webData.members.find(item => item.id === id)
  },
  getSeller: (state) => (id) => { // 根据ID获取1条卖家信息
    return state.webData.sellers.find(item => item.id === id)
  },
  getFile: (state) => (id) => { // 根据ID获取1条文件信息
    return state.webData.files.find(item => item.id === id)
  },
  getFiles: (state) => { // 根据flag=1的文件列表
    return state.webData.files.filter(item => item.flag === 1)
  }
}
const mutations = {
  setWebData (state, payload) {
    state.webData = payload
  },
  setTitle (state, payload) {
    state.title = payload
  }
}
const store = {
  namespaced: true,
  state,
  getters,
  mutations
}
export default store
